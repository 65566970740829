.myflatmates-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
}

.myflatmates-container {
    max-width: 1140px;
    margin: auto;
    padding-bottom: 5rem;
}

.myflatmates-title-maincontainer {
    background: #07133f;
}
