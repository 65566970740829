@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {

  /* Default Colors */

  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-red: #d31612;
  --color-green: #00FF38;
  --color-yellow: #cdd317;
  --color-blue: #1743d3;

}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

body {
  background: var(--color-black);
}

h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #17cf97;
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
}


.btn-light {
  /* background: rgba(255, 255, 255, 0.2); */
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}