.bcg-img {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 60vh;
    position: relative;
}

.bcg-img::before {
    content: "";
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.heading {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bcg-img h1 {
    font-size: 2.4rem;
}

.bcg-img p {
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .bcg-img h1 {
        font-size: 2rem;
    }
}

.btn {
    margin: 1rem 0.2rem;
}

.content {
    margin-top: 2em;
}