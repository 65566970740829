.h-divider {
    margin: auto;
    margin-top: 80px;
    width: 80%;
    position: relative;
}

.h-divider .shadow {
    overflow: hidden;
    height: 20px;
}

.h-divider .shadow:after {
    content: '';
    display: block;
    margin: -25px auto 0;
    width: 100%;
    height: 25px;
    border-radius: 125px/12px;
    box-shadow: 0 0 8px rgb(115, 115, 115);
}

.h-divider img {
    position: absolute;
    margin: 4px;
    max-width: 60px;
    border-radius: 100%;
    border: 1px dashed #aaa;
}