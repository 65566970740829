.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.main-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.mask::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-black);
  opacity: 0.8;
}

.bcg {
  height: 100%;
  width: 100%;
}

.bcg .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
}

.bcg .content h1 {
  font-size: 4rem;
  padding: 0.6rem 0 1.5rem;
}

.bcg .content p {
  font-size: 1.4rem;
  font-weight: 200;
  text-transform: uppercase;
}

.content .btn {
  margin: 1rem 0.2rem;
}

@media screen and (max-width: 640px) {
  .bcg .content h1 {
    font-size: 3rem;
  }

  .bcg .content p {
    font-size: 1.4rem;
  }
}


.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}

.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.rotate.down {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}